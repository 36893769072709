import { Container, Row, Col } from "react-bootstrap";
import { MailchimpForm } from "./MailchimpForm";
import logo from "../assets/img/logo.svg";
import navIcon1 from "../assets/img/nav-icon1.svg";
import navIcon2 from "../assets/img/github0.svg";
import navIcon3 from "../assets/img/nav-icon3.svg";
import React from 'react';

export const Footer = () => {
  return (
    <footer className="footer">
      <Container>
        <Row className="align-items-center">
          <MailchimpForm />
          <Col size={12} sm={6}>
            <img src={logo} alt="Logo" />
          </Col>
          <Col size={12} sm={6} className="text-center text-sm-end">
            <div className="social-icon">
              <a href="https://www.linkedin.com/in/junewengtan/" target="_blank" rel="noreferrer noopener"> <img src={navIcon1} alt="Icon" /></a>
              <a href="https://github.com/ItsTrixinity" target="_blank" rel="noreferrer noopener"><img src={navIcon2} alt="Icon" /></a>
              <a href="https://www.instagram.com/itstrixinity_/" target="_blank" rel="noreferrer noopener"><img src={navIcon3} alt="Icon" /></a>
            </div>
            <p>Crafted with love by Daniel Tan.</p>
          </Col>
        </Row>
      </Container>
    </footer>
  )
}
